import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import ConnectPage from "../components/pages/connect-page"

const PageWelcome = ({ data, location, pageContext }) => {

  const post = data?.nodePage
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const events = data?.event
  const ministries = data?.ministries
  const promoted = data?.promoted
  const toknowus = data?.toknowus
  const translationPaths = {
   en: `/en${post?.path?.alias}`,
   es: `/es${post?.path?.alias}`,
   ca: `${post?.path?.alias}`
 }
  return (
    <Layout
      location={location}
      langcode={post.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post.title}
        lang={post.langcode}
        description={post.body.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
       {pageContext?.type === 'welcome' &&
        <ConnectPage
          post={post}
          lang={post.langcode}
          events={events}
          bannerHeight="420px"
          ministries={ministries}
          promoted={promoted}
          buttonBanner="Vull saber més"
          toknowus={toknowus}
         />
       }
      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
query  PageWelcome ($drupal_internal__nid: Int, $langcode: String!) {
      nodePage(
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ) {
        langcode
        drupal_internal__nid
        id
        title
        drupal_id
        body {
          value
          summary
        }
        path{
          alias
        }
        created
        relationships {
          field_image {
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
      }
      toknowus: nodeWelcome {
        field_connect_body {
          processed
          value
        }
        field_connect_cta {
          uri
          url
          title
        }
        field_connect_related_body_1 {
          processed
          value
        }
        field_connect_related_body_2 {
          processed
          value
        }
        field_connect_related_body_3 {
          processed
          value
        }
        field_connect_related_body_4 {
          processed
          value
        }
        field_connect_related_cta_1 {
          title
          uri
          url
        }
        field_connect_related_cta_2 {
          title
          uri
          url
        }
        field_connect_related_cta_3 {
          title
          uri
          url
        }
        field_connect_related_cta_4 {
          title
          uri
          url
        }
        field_connect_related_title_1
        field_connect_related_title_2
        field_connect_related_title_3
        field_connect_related_title_4
        field_connect_title
        field_field_meet_body_1 {
          processed
          value
        }
        field_meet_body_2 {
          processed
          value
        }
        field_mark_difference_body {
          processed
          value
        }
        field_mark_difference_title
        field_markdif_related_title_1
        field_markdif_related_title_2
        field_markdif_related_body_1 {
          processed
          value
        }
        field_markdif_related_body_2 {
          processed
          value
        }
        field_markdif_related_cta_1 {
          title
          uri
          url
        }
        field_markdif_related_cta_2 {
          title
          uri
          url
        }
        field_meet_body_2 {
          processed
          value
        }
        field_meet_cta_1 {
          title
          uri
          url
        }
        field_meet_cta_2 {
          title
          uri
          url
        }
        field_visit_body_3 {
          processed
          value
        }
        field_visit_body_1 {
          processed
          value
        }
        field_visit_body_2 {
          processed
          value
        }
        field_visit_body_4 {
          processed
          value
        }
        field_visit_cta_1 {
          title
          uri
          url
        }
        field_visit_cta_2 {
          title
          uri
          url
        }
        field_visit_cta_3 {
          title
          uri
          url
        }
        field_visit_cta_4 {
          title
          uri
          url
        }
        field_visit_social_media_body {
          processed
          value
        }
        field_visit_title
        relationships {
          field_connect_related_image_1 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_connect_related_image_2 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_connect_related_image_3 {
            image_style_uri {
              _120x120
              _294x192
              _1440x408
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_connect_related_image_4 {
            image_style_uri {
              _120x120
              _294x192
              _1440x408
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_markdif_related_image_1 {
            image_style_uri {
              _120x120
              _1440x408
              _541x359
              _294x192
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_markdif_related_image_2 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_meet_image_1 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              medium
              large
              thumbnail
              wide
            }
          }
          field_meet_image_2 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_visit_image_1 {
            image_style_uri {
              _120x120
              _1440x408
              _541x359
              _653_432
              _294x192
              large
              medium
              thumbnail
              wide
            }
          }
          field_visit_image_2 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_visit_image_3 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_visit_image_4 {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
      }
      promoted: nodeSubministry(
        field_show_on_here_for_you: {eq: "promoted"},
        langcode: { eq: $langcode }
       ) {
        field_show_on_here_for_you
        langcode
        path {
          alias
        }
        title
        field_form {
          drupal_internal__target_id
        }
        relationships {
          field_form {
            elements {
              name
              options {
                label
                value
              }
              type
              attributes {
                name
                value
              }
            }
            title
          }
          field_image {
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
        body {
          summary
          value
        }

    }
}
`

export default PageWelcome
